.TokenSelector .Modal .Modal-content {
  width: 31rem;
  margin: 0;
  max-height: 100vh;
}

.TokenSelector-token-name {
  margin-right: 1.5rem;
}

.TokenSelector.disabled {
  pointer-events: none;
}

.TokenSelector.disabled .TokenSelector-caret {
  display: none;
}

.TokenSelector-box {
  display: block;
  cursor: pointer;
  border-radius: 0.6rem;
  text-align: right;
  white-space: nowrap;
}

.TokenSelector-box:hover {
  color: #7885ff;
}

.Token-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Token-info .Token-symbol {
  margin-left: 0.8rem;
  display: flex;
  flex-direction: column;
}
.Token-balance {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.Token-info .token-logo {
  display: block !important;
  width: 4rem;
  height: 4rem;
  margin-right: 2px;
  margin-left: 0 !important;
}

.TokenSelector-caret {
  vertical-align: middle;
  display: inline-block;
  margin-left: 0.465rem;
  font-size: 2.325rem;
  margin-top: -0.465rem;
}

.TokenSelector-token-row {
  cursor: pointer;
  border: 1px solid #ffffff29;
  position: relative;
  background: transparent;
  border-radius: var(--border-radius-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  margin-bottom: 0.8rem;
}

.TokenSelector-tooltip.Tooltip {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.TokenSelector-tooltip-backing {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}


.TokenSelector-token-row.disabled {
  cursor: not-allowed;
}

.TokenSelector-token-row.disabled .Token-info {
  opacity: 0.3;
}

.TokenSelector-token-row.disabled .Token-balance {
  opacity: 0.3;
}

.TokenSelector-token-row.TokenSelector-token-input-row {
  padding: 0;
}

.TokenSelector-token-row input {
  padding: 0.8rem;
  width: 100%;
}

.TokenSelector-token-row .Token-text {
  font-size: 1.6rem;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0px;
}
.TokenSelector-token-row:hover {
  background: var(--dark-blue-hover);
}

.TokenSelector.right .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}
.text-accent {
  color: var(--dark-blue-accent);
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0;
}

.TokenSelector.right .Modal-content {
  position: absolute;
  width: 35.65rem;
  top: 0;
  bottom: 0;
  overflow: auto;
  border: none;
}

@media (max-width: 700px) {
  .TokenSelector.right .Modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 3.1rem;
    top: 0;
    right: 3.1rem;
    bottom: 0;
  }
  .Token-info .token-logo {
    width: 3rem;
    height: 3rem;
    margin-right: 0.8rem;
  }
}
