.center {
  text-align: center;
}
.section {
  margin-bottom: 4.65rem;
}
.Page-subtitle {
  font-size: 2.325rem;
  font-weight: 500;
  margin: 1.5rem 0;
}
.content {
  margin-top: 4.65rem;
}
.body-text {
  margin: 0px;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.325rem;
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  /* color: #a0a3c4; */
}

.body-title {
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: 2.325rem;
}

.list-style-none {
  list-style: none;
}
.list-style-none li {
  padding-bottom: 0;
}
